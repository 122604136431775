/* RESPONSIBLE TEAM: team-ai-insights */
import OptimizeFin from 'embercom/routes/apps/app/automation/fin-ai-agent/analyze/optimize-fin';

export default class FinStandaloneOptimizeFin extends OptimizeFin {
  analytics = {
    section: 'standalone',
    place: 'optimize-fin',
  };

  templateName = 'apps/app/automation/fin-ai-agent/analyze/optimize-fin';
  routeWhenNotOptedIn = 'apps.app.standalone.setup';
}
