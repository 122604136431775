/* RESPONSIBLE TEAM: team-ai-agent */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { taskFor } from 'ember-concurrency-ts';
import type IntlService from 'embercom/services/intl';
import type RouterService from '@ember/routing/router-service';
import type ReportingMetrics from 'embercom/services/reporting-metrics';
import type Transition from '@ember/routing/transition';

export default class OptimizeFinRoute extends Route {
  @service declare intl: IntlService;
  @service declare appService: $TSFixMe;
  @service declare router: RouterService;
  @service declare reportingMetrics: ReportingMetrics;
  @service declare permissionsService: $TSFixMe;

  analytics = {
    section: 'automation',
    place: 'optimize-fin',
  };

  routeWhenNotOptedIn = 'apps.app.automation.fin-ai-agent.analyze.ai-insights';

  get titleToken() {
    return this.intl.t('ai-agent.analyze.optimize-fin');
  }

  async beforeModel(transition: Transition) {
    if (!this.appService.app.canSeeAiInsightsBeta) {
      this.router.transitionTo(this.routeWhenNotOptedIn);
      return;
    }
    this.permissionsService.ensurePermissionWhenTransitioning('can_access_reporting', transition);
    await taskFor(this.reportingMetrics.setup).perform();
  }
}
